import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";

const LicenseAgreement = ({ pageContext: { lang }, location: { pathname } }) => (
    <Layout>
        <Seo
            title="Лицензионное соглашение"
            lang='ru'
        /> 

        <Navbar
            currentPath={pathname}
            lang={lang}
        />

        <div className="faq-area ptb-80">
            <div className="container license-agreement">
                <h1>Лицензионное соглашение (оферта)</h1>
                <ol className="license-agreement-list">
                    <li>
                        <section>
                            <h2>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
                            
                            <p>1.1. В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и определения:</p>

                            <ol type="a">
                                <li>Лицензиар – OOO “Фьюзор”, ОГРН 1127847612672, адрес: 191023, г. Санкт-Петербург, ул. Садовая, д. 23/6, литера А, пом. 3-Н эт. 4 оф. 7.</li>
                                <li>Лицензиат – лицо, способное совершить Акцепт (применительно к порядку заключения Договора) либо совершившее Акцепт (применительно к исполнению заключенного Договора).</li>
                                <li>Оферта – текст настоящего документа со всеми приложениями, изменениями и дополнениями к нему, размещенный на Сайте Лицензиара и доступный в сети Интернет по адресу: <a href="https://fuzorhq.com/license-agreement-rus">http://fuzorhq.com/license-agreement-rus</a></li>
                                <li>Акцепт – полное и безоговорочное принятие Оферты на изложенных в ней условиях путем совершения Лицензиатом определенных действий, создающее Договор между Лицензиаром и Лицензиатом.</li>
                                <li>Заказ – предоставление Лицензиатом информации о требуемых Продуктах, выбор условий лицензирования, и совершение иных действий, перечисленных на соответствующей странице Сайта и необходимых для оформления отдельного Договора.</li>
                                <li>Договор – лицензионный договор на использование Продуктов, заключенный между Лицензиаром и Лицензиатом на условиях Оферты.</li>
                                <li>Продукты – программы для ЭВМ, базы данных и относящаяся к ним техническая документация и/или руководства по использованию (при наличии таковых).</li>
                                <li>Сайт Лицензиара / Сайт – автоматизированная информационная система, доступная в сети Интернет по сетевому адресу: <a href="https://fuzorhq.com">http://fuzorhq.com</a></li>
                            </ol>

                            <p>1.2. В настоящей Оферте могут быть использованы термины и определения, не определенные в п.1.1. Оферты. В этом случае толкование такого термина производится в соответствии с текстом Оферты. В случае отсутствия однозначного толкования термина или определения в тексте Оферты следует руководствоваться его толкованием, определенным: в первую очередь – документами, образующими Договор между Сторонами, во вторую очередь – законодательством РФ, и в последующем – обычаями делового оборота и научной доктриной.</p>
                            <p>1.3. Любая ссылка в настоящей Оферте на пункт (раздел Оферты) и/или ее условия, означает соответствующую ссылку на настоящую Оферту (ее раздел) и/или ее условия.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>ПРЕДМЕТ ДОГОВОРА</h2>

                            <p>2.1. Лицензиар обязуется предоставить Лицензиату за вознаграждение право использования Продуктов на условиях простой (неисключительной) лицензии.</p>
                            <p>2.2. Наименование и краткие характеристики Продуктов, а также перечень относящейся к ним документации указанны или доступны на Сайте по адресу: <a href="https://fuzorhq.com">http://fuzorhq.com</a>. При оформлении на основании Оферты отдельного счета, подлежащего оплате Лицензиатом, наименование Продуктов и иные необходимые условия их лицензирования могут излагаться в таком счете.</p>
                            <p>2.3. Указанные в Оферте разделы Сайта и/или выставляемые на основании Оферты счета, содержащие указание на лицензируемые Продукты и иные условия предоставления лицензии, являются обязательными для Сторон документами и составляют неотъемлемую часть настоящей Оферты, а также заключаемого на ее основе Договора.</p>
                            <p>2.4. Если иное не предусмотрено в описании Продуктов на Сайте или в относящемся к ним счете, право использования Продуктов предоставляется с момента их передачи и/или предоставления к ним доступа Лицензиату на весь срок охраны исключительного права и на территорию всего мира.</p>
                            <p>2.5. Если иное не предусмотрено в описании Продуктов на Сайте или в относящемся к ним счете, в рамках простой (неисключительной) лицензии Лицензиату предоставляется право использования Продуктов следующими способами:</p>
                            <p>2.5.1. Продуктовая лицензия на сервер:</p>
                            <p>Право на воспроизведение
                            Воспроизведение Продуктов разрешается путем записи согласованного в Договоре количества экземпляров Продуктов в память серверного оборудования Лицензиата, строго соответствующего количеству таких экземпляров.
                            Не допускается воспроизведение Продуктов: на иных видах материальных носителей; в количестве, превышающем установленные ограничения; с нарушением требований документации на Продукты.</p>
                            <p>Право на использование по функциональному назначению (применение)
                            Лицензиат вправе использовать Продукты и документацию по функциональному назначению в пределах и в строгом соответствии с требованиями и ограничениями, в явном виде выраженными в Оферте и предоставляемой Лицензиаром документацией на Продукты.</p>
                            <p>2.5.2. Продуктовая лицензия на онлайн-доступ к платформе</p>
                            <p>Право на использование по функциональному назначению (применение)
                            Лицензиат вправе использовать Продукты и документацию по функциональному назначению в пределах и в строгом соответствии с требованиями и ограничениями, в явном виде выраженными в Оферте и предоставляемой Лицензиаром документацией на Продукты. Использование Продуктов ограничено согласованными в Договоре доменами Лицензиата.</p>
                            <p>2.5.3. Лицензия разработчика на сервер</p>
                            <p>Право на воспроизведение
                            Воспроизведение Продуктов разрешается путем записи согласованного в Договоре количества экземпляров Продуктов в память серверного оборудования Лицензиата, строго соответствующего количеству таких экземпляров.
                            Не допускается воспроизведение Продуктов: на иных видах материальных носителей; в количестве, превышающем установленные ограничения; с нарушением требований документации на Продукты.</p>
                            <p>Право на использование по функциональному назначению (применение)
                            Лицензиат вправе использовать Продукты и документацию по функциональному назначению в пределах и в строгом соответствии с требованиями и ограничениями, в явном виде выраженными в Оферте и предоставляемой Лицензиаром документацией на Продукты.
                            Использование Продуктов ограничено целью разработки и тестирования программного обеспечения Лицензиатом.</p>
                            <p>2.6. Запрещается воспроизводить, модифицировать, адаптировать и применять Продукты с нарушением условий настоящей Оферты, для других целей, кроме целей, явно указанных в Оферте.</p>
                            <p>2.7. Не допускается предоставление права использования Продуктов третьим лицам в порядке сублицензирования или уступка лицензии без предварительного письменного согласия Лицензиара, которое может выражаться путем прямого указания в счете на такие Продукты.</p>
                            <p>2.8. Права и способы использования Продуктов в явном виде не предоставленные/разрешенные Лицензиату по настоящей Оферте, считаются непредоставленными/запрещенными Лицензиаром.</p>
                            <p>2.9. Любой экземпляр (копия) Продукта на любом материальном носителе, включая все компоненты Продукта, выполненный и применяемый с нарушением условий настоящей Оферты, должен быть немедленно уничтожен, наличие у лица такого экземпляра (копии) Продукта не считается правомерным владением экземпляром, разрешение на ее использование прекращается.</p>
                            <p>2.10. Права на Продукты, а также все патенты, авторские права, торговые секреты и иные имущественные права на Продукты или связанные с ними, находятся и останутся в исключительной собственности Лицензиара, независимо от того признаются и защищаются ли данные права в соответствии с правом страны, где данные Продукты используются.</p>
                            <p>2.11. Лицензиар будет владеть всеми правами на любые копии, переводы, модификации, адаптации или изменения Продуктов, включая любые касающиеся их улучшения и разработки.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>АКЦЕПТ ОФЕРТЫ И ЗАКЛЮЧЕНИЕ ДОГОВОРА</h2>

                            <p>3.1. Если иное не предусмотрено условиями отдельного счета на Продукты, Акцепт совершается Лицензиатом путем выполнения совокупности указанных ниже действий:</p>
                            <p>3.2.1. оформление и направление Заказа с помощью программных средств на Сайте Лицензиара или на адрес электронной почты Лицензиара;</p>
                            <p>3.2.2. внесение авансового платежа в счет лицензионного вознаграждения за использование Продуктов в сумме, указанной на Сайте Лицензиара на дату оплаты.</p>
                            <p>3.3. Акцепт Оферты Лицензиатом создает Договор между Лицензиаром и Лицензиатом (статьи 433, 438 Гражданского Кодекса Российской Федерации) на условиях настоящей Оферты, включая указанные в ней обязательные для Сторон документы.</p>
                            <p>3.4. Во избежание сомнений, начало использования Продуктов Лицензиатом безусловно свидетельствует о заключении Договора в соответствии с условиями настоящей Оферты.</p>
                            <p>3.5. На основании Оферты с Лицензиатом может быть заключено неограниченное количество Договоров.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>ПРЕДОСТВЛЕНИЕ ПРОДУКТОВ И ЛИЦЕНЗИИ</h2>

                            <p>4.1. Порядок предоставления Продуктов и лицензии на них зависит от вида Продуктов и условий их лицензирования на основании Оферты и соответствующего Заказа и реализуется Лицензиаром путем предоставления Лицензиату доступа к Продуктам по сети Интернет и передачи (активации) лицензионного ключа, соответствующего номеру Заказа.</p>
                            <p>4.2. Передача ключа по Договору выполняется Лицензиаром путем его направления электронным письмом в ответ на электронное письмо Лицензиата с указанием соответствующего такому Договору номера Заказа и уникального идентификатора серверного оборудования, сгенерированного с помощью предоставленных Продуктов Лицензиара.</p>
                            <p>4.3. В случае приобретения продуктовой лицензии на онлайн-доступ к платформе, Лицензиар выполняет активацию лицензионного ключа для определенного домена без его передачи Лицензиату.</p>
                            <p>4.4. Лицензиат не обязан представлять Лицензиару отчеты об использовании Продуктов.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>ВОЗНАГРАЖДЕНИЕ И ПОРЯДОК РАСЧЕТОВ</h2>

                            <p>5.1. В качестве лицензионного вознаграждения за предоставление права использования Продуктов Лицензиат обязуется уплатить Лицензиару лицензионное вознаграждение по тарифам, указанным на Сайте Лицензиара на дату оплаты. При оформлении на основании Оферты отдельного счета на предоставление определенных Продуктов, размер лицензионного вознаграждения указывается в таком счете.</p>
                            <p>5.2. Лицензионное вознаграждение НДС не облагается в связи с применением Лицензиаром упрощенной системы налогообложения на основании гл. 26.2 НК РФ.</p>
                            <p>5.3. Если иное не предусмотрено с отдельном счете на определенные Продукты, Лицензиат обязан уплатить Лицензиару лицензионное вознаграждение в полном объеме в порядке 100% предоплаты в день направления соответствующего Заказа.</p>
                            <p>5.4. Все платежи осуществляются в российских рублях платежными поручениями на расчетный счет Лицензиара.</p>
                            <p>5.5. Обязательства Лицензиата по оплате считаются выполненными с момента зачисления денежных средств в требуемой сумме на корреспондентский счет банка Лицензиара.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>ГАРАНТИИ. ОТВЕТСТВЕННОСТЬ СТОРОН. ФОРС-МАЖОР</h2>
                            <p>6.1. Лицензиар подтверждает и гарантирует:</p>
                            <p>что Лицензиару принадлежат исключительные права на использование Продуктов,
                            что условия настоящего Договора не нарушают прав интеллектуальной собственности и иных прав третьих лиц на указанный Продукты или в связи с ними.</p>
                            <p>6.2. Лицензиар предоставляет гарантии в отношении Продуктов в той степени, которая установлена настоящей Офертой и документацией на Продукты. Кроме тех случаев, когда это явно указано в настоящей Оферте, Лицензиар не предоставляет никаких дополнительных явных или подразумеваемых гарантий относительно Продуктов, предоставленных по Договору. Лицензиар гарантирует соответствие Продуктов, указанным в предоставляемой на них документации целям использования и функциональным возможностям, отсутствие в составе Продуктов вредоносного программного обеспечения.</p>
                            <p>6.3. Лицензиар обязуется устранить недостатки в Продуктах согласно предоставленной гарантии на них, о которых заявлено Лицензиатом в течение 1 (одного) месяца с момента заключения в отношении них Договора.</p>
                            <p>6.4. Лицензиар отказывается от любых гарантий и условий в отношении Продуктов, выраженных, подразумеваемых или установленных законом, касающихся их товарного состояния, пригодности для целей Лицензиата, совместного использования с программным обеспечением и аппаратными средствами за пределами условий и ограничений, установленных настоящей Офертой и документацией на Продукты. Предоставленные по настоящей Оферте гарантии не распространяются на Продукт, который был модифицирован или изменен Лицензиатом или третьим лицом по заказу Лицензиата, не обслуживался в соответствии с рекомендациям Лицензиара, использовался способом, отличным от указанного в настоящей Оферте и документации, предоставленной Лицензиаром, использовался с нарушениями правил эксплуатации, предусмотренных документацией на него, включая использование на аппаратных средствах или совместно с программным обеспечением, которые не были рекомендованы Лицензиаром.</p>
                            <p>6.5. Лицензиар не отвечает за убытки Лицензиата, вызванные обстоятельствами, которые не охватываются предоставленной по настоящей Оферте гарантией. Ответственность Лицензиара по Договору в любом случае ограничивается возмещением реального ущерба Лицензиату в сумме, не превышающей 10% (десять процентов) от уплаченного по соответствующему Договору лицензионного вознаграждения.</p>
                            <p>6.6. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после заключения Договора в результате событий чрезвычайного характера, которые стороны не могли ни предвидеть, ни предотвратить разумными мерами. К таким событиям чрезвычайного характера относятся: наводнение, пожар, землетрясение, взрыв, оседание почвы, эпидемии и иные явления природы, а также война или военные действия, забастовка в отрасли или регионе, принятие органом государственной власти, органом местного самоуправления правового акта, повлекшие невозможность исполнения настоящего Договора.</p>
                            <p>6.7. При наступлении и прекращении указанных в п. 6.6 обстоятельств Сторона, для которой создалась невозможность исполнения ее обязательств, должна в течение 5 (пяти) дней письменно известить об этом другую Сторону, и подтвердить наличие таких обстоятельств справкой, выданной Торгово-промышленной палатой или иным компетентным органом.</p>
                            <p>6.8. При отсутствии своевременного извещения, предусмотренного в п. 6.7, Сторона, для которой указанными обстоятельствами создана невозможность исполнения обязательств, не вправе ссылаться на указанные обстоятельства в качестве основания для освобождения от ответственности.</p>
                            <p>6.9. В случае возникновения обстоятельств непреодолимой силы срок выполнения обязательств по Договору отодвигается соразмерно времени, в течение которого действуют такие обязательства и их последствия.</p>
                            <p>6.10. Если обстоятельства, предусмотренные настоящей статьей, продлятся свыше трех месяцев, Стороны должны договориться о судьбе Договора. Если Стороны не придут к согласию, Сторона, которая затронута обстоятельствами непреодолимой силы, вправе расторгнуть Договор, письменно уведомив об этом другую Сторону.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>РАЗРЕШЕНИЕ СПОРОВ</h2>

                            <p>7.1. Применимое право. Настоящая Оферта, заключение и исполнение Договора на ее основе, а также иные отношения Сторон по Договору или в связи с ним регулируются действующим законодательством Российской Федерации. Все вопросы, не урегулированные настоящей Офертой и/или заключенным на ее основе Договором, регулируются в соответствии с материальным правом Российской Федерации.</p>
                            <p>7.2. В случае возникновения споров между Сторонами по вопросам, предусмотренным настоящей Офертой или заключенным на ее основе Договором, а также в связи с ними, Стороны примут все меры к их разрешению в претензионном порядке.</p>
                            <p>7.3. Арбитражная оговорка. В случае недостижения Сторонами согласия, все споры, разногласия или требования, возникающие из настоящей Оферты или заключенного в соответствии с ней Договора, или в связи с указанными документами, в том числе касающиеся их толкования, исполнения, нарушения, прекращения или недействительности, подлежат разрешению в Арбитражном суде Санкт-Петербурга и Ленинградской области в соответствии с российским процессуальным правом.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ ОФЕРТЫ</h2>

                            <p>8.1. Оферта вступает в силу с момента размещения на Сайте Лицензиара и действует до момента ее отзыва Лицензиаром.</p>
                            <p>8.2. Лицензиар оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. Сведения об изменении или отзыве Оферты доводятся до Лицензиата по выбору Лицензиара посредством размещения на Сайте Лицензиара, либо путем направления соответствующего уведомления на электронный или почтовый адрес, указанный Лицензиатом при оформлении Заказа или в ходе его исполнения.</p>
                            <p>8.3. В случае отзыва Оферты или внесения изменений в Оферту, последние вступают в силу с момента доведения об этом сведений до Лицензиата, если иной срок вступления их в силу не определен Офертой или дополнительно при таком сообщении.</p>
                            <p>8.4. Размещаемые на Сайте обязательные для Сторон документы утверждаются, дополняются и изменяются Лицензиаром по собственному усмотрению и доводятся до сведения Лицензиата в порядке, предусмотренном для уведомления Лицензиата об изменении Оферты.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>СРОК ДЕЙСТВИЯ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА</h2>

                            <p>9.1. Договор вступает в силу с момента Акцепта Оферты Лицензиатом и действует: а) в течение всего срока лицензии на Продукты, предоставленные по такому Договору, либо б) до момента досрочного расторжения Договора.</p>
                            <p>9.2. В случае отзыва Оферты Лицензиаром в течение срока действия Договора, Договор считается действующим на условиях Оферты в последней редакции со всеми обязательными для Сторон документами.</p>
                            <p>9.3. Договор может быть расторгнут досрочно по взаимному соглашению Сторон.</p>
                            <p>9.4. При нарушении Лицензиатом обязанности уплатить Лицензиару в установленный срок лицензионное вознаграждение Лицензиар может незамедлительно в одностороннем порядке отказаться от исполнения Договора и потребовать возмещения убытков, причиненных расторжением Договора.</p>
                            <p>9.5. В случае неисполнения/просрочки исполнения Лицензиатом других обязательств по Договору более чем на 30 (Тридцать) дней, Лицензиар может также в одностороннем порядке отказаться от исполнения такого Договора и потребовать возмещения убытков, причиненных расторжением Договора.</p>
                            <p>9.6. Уведомление об отказе от исполнения Договора направляется в электронной форме на указанный им адрес электронной почты. Договор считается прекратившим действие полностью или в указанной в таком уведомлении части по истечении 3 (трех) дней с момента направления такого уведомления.</p>
                            <p>9.7. Лицензиат вправе уступить права по Договору только в полном объеме и при условии получения предварительного письменного согласия Лицензиара.</p>
                            <p>9.8. В случае прекращения Договора (в том числе досрочного), а также прекращения прав на использование Продуктов (включая уступку прав третьему лицу), Лицензиат обязан незамедлительно прекратить использование Продуктов.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ ЭЛЕКТРОННОЙ ПОДПИСИ</h2>
                            
                            <p>10.1. Во взаимоотношениях между Лицензиаром и Лицензиатом могут использоваться электронные документы, удостоверенные простой электронной подписью.</p>
                            <p>10.2. Простой электронной подписью признается электронная подпись, которая посредством использования ключа электронной подписи в виде логина и пароля Лицензиата или адреса электронной почты Лицензиата, указанного при оформлении Заказа на Сайте, подтверждает факт формирования электронной подписи непосредственно Лицензиатом.</p>
                            <p>10.3. По соглашению Сторон электронные документы, подписанные простой электронной подписью, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью.</p>
                            <p>10.4. Лицензиар определяет Лицензиата, которому соответствует простая электронная подпись, по используемому Лицензиатом логину и паролю, указанному при регистрации и/или авторизации на Сайте, – в случае совершения любых действий на Сайте, либо по используемому Лицензиатом адресу электронной почты, указанному при оформлении Заказа на Сайте, – в случае поступления Лицензиару сообщений с такого адреса.</p>
                            <p>10.5. Любые действия, совершенные с использованием простой электронной подписи определенного Лицензиата, считаются совершенными таким Лицензиатом.</p>
                            <p>10.6. Оформление Лицензиатом Заказа с помощью программных средств на Сайте или путем направления Лицензиару электронного письма с адреса, указанного ранее на Сайте, свидетельствует о подписании такого электронного документа простой электронной подписью Лицензиата и подтверждает его намерение заключить Договор на условиях, указанных в настоящей Оферте.</p>
                            <p>10.7. Лицензиат обязуется соблюдать конфиденциальность ключа электронной подписи. В частности, Лицензиат не имеет права передавать свои логин и пароль или предоставлять доступ к своей электронной почте третьим лицам, и несет полную ответственность за их сохранность и индивидуальное использование, самостоятельно выбирая способ их хранения и ограничения к ним доступа.</p>
                            <p>10.8. В случае несанкционированного доступа к логину и паролю, их утраты или раскрытия третьим лицам Лицензиат обязан незамедлительно сообщить об этом Лицензиару путем направления электронного письма с указанного на Сайте адреса электронной почты.</p>
                            <p>10.9. В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан на Сайте, Лицензиат обязан незамедлительно заменить такой адрес на новый и сообщить о данном факте Лицензиару путем изменения сведений о таком адресе на Сайте.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>ПРОЧИЕ УСЛОВИЯ</h2>

                            <p>11.1. Лицензиар вправе запрашивать дополнительную информацию, такую как ксерокопии документов, удостоверяющих личность или полномочия действовать от лица Лицензиата, банковские реквизиты, в целях предотвращения мошенничества.</p>
                            <p>11.2. В случае если одно или более положений Оферты или Договора являются по какой-либо причине недействительными, не имеющими юридической силы, такая недействительность не оказывает влияния на действительность любого другого положения Оферты или Договора, которые остаются в силе.</p>
                            <p>11.3. Не вступая в противоречие с условиями Оферты, Стороны вправе в любое время оформить заключенный Договор в форме письменного документа, выражающего содержание действующей на момент его оформления Оферты, указанных в ней Обязательных документов и размещенного Заказа.</p>
                        </section>
                    </li>
                    <li>
                        <section>
                            <h2>РЕКВИЗИТЫ ЛИЦЕНЗИАРА</h2>

                            <p>ООО “Фьюзор”</p>
                            <p>Адрес: 191023, г. Санкт-Петербург, ул. Садовая, д. 23/6, литера А, пом. 3-Н эт. 4 оф. 7</p>
                            <p>ИНН: 7839470881</p>
                        </section>
                    </li>
                </ol>
            </div>
        </div>

        <Footer lang={lang} />
    </Layout>
)

export default LicenseAgreement;